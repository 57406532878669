import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: friendsJson {
      accelerated {
        title
        logos {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                width: 400
              )
            }
          }
        }
      }
    }
  }
`;

export default function index() {
  const { data } = useStaticQuery(query);
  const { accelerated } = data;

  return (
    <section>
      <Container>
        <div className="space-y-16">
          <div className="text-center space-y-10">
            <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {accelerated.title}
            </h2>
          </div>
          <div className="flex justify-center">
            {Object.keys(accelerated.logos).map((key) => {
              const image = getImage(accelerated.logos[key].image);
              return (
                <GatsbyImage
                  key={key}
                  image={image}
                  alt={accelerated.logos[key].name}
                  className="basis-full md:basis-1/2"
                />
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
}
