import * as React from "react";
import SEO from "../components/Featured/SEO";
import PartnersSection from "../components/Featured/Friends/PartnersSection";
import AcceleratedSection from "../components/Featured/Friends/AcceleratedSection";
import JoinedSection from "../components/Featured/Friends/JoinedSection";

function IndexPage() {
  return (
    <div className="space-y-44 py-44">
      <SEO />
      <AcceleratedSection />
      <PartnersSection />
      <JoinedSection />
    </div>
  );
}

export default IndexPage;
