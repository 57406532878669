import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: friendsJson {
      joined {
        title
        description
        partners {
          name
          icon_id
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 160)
            }
          }
        }
      }
    }
  }
`;

export default function index() {
  const { data } = useStaticQuery(query);
  const { joined } = data;

  return (
    <section>
      <Container>
        <div className="space-y-16">
          <div className="text-center space-y-10">
            <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {joined.title}
            </h2>
          </div>
          <div className="flex items-center justify-center flex-wrap gap-y-12">
            {Object.keys(joined.partners).map((key) => {
              const image = getImage(joined.partners[key].image);
              return (
                <div
                  key={key}
                  className="basis-1/2 md:basis-1/3 lg:basis-1/4 shrink-0 flex justify-center items-center"
                >
                  <GatsbyImage
                    image={image}
                    alt={joined.partners[key].name}
                    objectFit="contain"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
}
